import { MAX_NUMBER, RentalHistoryField } from '~/assets/strings';
import { NoRentalHistoryError } from '~/errors/validationError';
import { RentalHistory, RentalHistoryErrorCode } from '~/types/RentalApplication';
import { MagicUseCase } from '~/use-cases/magicUseCase';

const relatedErrors = {
  landlordName: [RentalHistoryErrorCode.InvalidRentalHistoryLandlordName],
  landlordPhone: [RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone],
  rent: [RentalHistoryErrorCode.InvalidRentalHistoryRent],
  moveInDate: [
    RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate,
    RentalHistoryErrorCode.InvalidRentalHistoryMoveInDateLater
  ],
  moveOutDate: [
    RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDate,
    RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDateEarlier
  ],
  reasonForLeaving: [RentalHistoryErrorCode.InvalidRentalHistoryReasonForLeaving],
  address1: [RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1],
  address2: [RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2],
  city: [RentalHistoryErrorCode.InvalidRentalHistoryCity],
  state: [RentalHistoryErrorCode.InvalidRentalHistoryState],
  zipCode: [
    RentalHistoryErrorCode.InvalidRentalHistoryZipCode
  ],
  country: [RentalHistoryErrorCode.InvalidRentalHistoryCountry],
};

export class ValidateRentalHistoryUseCase extends MagicUseCase {
  private nameRegex = /^[a-zA-Z\s]{2,150}$/;
  private addressRegex = /^(?!.*([&][#]))[A-Za-z0-9 #(),&.,'_+~/*-]*$/;
  private cityRegex = /^[^0-9><=$?|%"`]{2,27}$/;
  private usZipRegex = /^[0-9]{5}$/;
  private canZipRegex = /^[A-Za-z][0-9][A-Za-z][\s-]?[0-9][A-Za-z][0-9]$/;
  protected async runLogic(fidleName?: string) {
    const rentalHistory = this.getState().user.rentalApplication.application?.residentialHistory;

    if (!this.getState().user.rentalApplication.rentalHistoryErrors) {
      this.getState().user.rentalApplication.rentalHistoryErrors = [];
    }

    if (!rentalHistory || rentalHistory.length === 0) {
      return;
    }

    if (fidleName) {
      this.validateSingleField(fidleName);
    } else {
      this.validateRentalHistory();
    }
  }

  private validateSingleField(fieldPath: string) {
    const rentalHistory = this.getState().user.rentalApplication.application?.residentialHistory;
    if (!rentalHistory || rentalHistory.length === 0) {
      throw new NoRentalHistoryError();
    }

    const fieldPathArr = fieldPath.split('.');
    const fieldName = fieldPathArr[2];
    if (fieldPathArr[0] !== 'history') {
      throw new NoRentalHistoryError();
    }

    const historyElement = rentalHistory[Number(fieldPathArr[1])];
    const errors: RentalHistoryErrorCode[] = [
      ...(this.getState().user.rentalApplication.rentalHistoryErrors[Number(fieldPathArr[1])] || []),
    ];

    switch (fieldName) {
      case RentalHistoryField.Address1:
        this.addErrorToArray(errors, this.validateAddress1(historyElement.address?.streetAddress1), fieldName);
        break;
      case RentalHistoryField.Address2:
        this.addErrorToArray(errors, this.validateAddress2(historyElement.address?.streetAddress2), fieldName);
        break;
      case RentalHistoryField.ReasonForLeaving:
        this.addErrorToArray(errors, this.validateReasonForLeaving(historyElement.reasonForLeaving), fieldName);
        break;
      case RentalHistoryField.City:
        this.addErrorToArray(errors, this.validateCity(historyElement.address?.city), fieldName);
        break;
      case RentalHistoryField.State:
        this.addErrorToArray(errors, this.validateState(historyElement.address?.state), fieldName);
        break;
      case RentalHistoryField.ZipCode:
        this.addErrorToArray(errors, this.validateZipCode(historyElement.address?.country, historyElement.address?.zipCode), fieldName);
        break;
      case RentalHistoryField.Country:
        this.addErrorToArray(errors, this.validateCountry(historyElement.address?.country), fieldName);
        break;
      case RentalHistoryField.LandlordName:
        this.addErrorToArray(errors, this.validateLandlordName(historyElement.landlordName), fieldName);
        break;
      case RentalHistoryField.LandlordPhone:
        this.addErrorToArray(errors, this.validateLandlordPhone(historyElement.landlordPhone), fieldName);
        break;
      case RentalHistoryField.Rent:
        this.addErrorToArray(errors, this.validateRent(historyElement.rent), fieldName);
        break;
      case RentalHistoryField.MoveInDate:
        this.addErrorToArray(errors, this.validateMoveInDate(historyElement.moveInDate), fieldName);
        break;
      case RentalHistoryField.MoveOutDate:
        this.addErrorToArray(errors, this.validateMoveOutDate(historyElement.moveOutDate, historyElement.moveInDate), fieldName);
        break;
      default:
        console.warn('No validation rule found for field:', fieldName);
        break;
    }

    const index = Number(fieldPathArr[1]);
    if (this.getState().user.rentalApplication.rentalHistoryErrors.length < index) {
      for (let i = 0; i < index; i++) {
        this.getState().user.rentalApplication.rentalHistoryErrors.push([]);
      }
    }
    this.getState().user.rentalApplication.rentalHistoryErrors[index] = errors;
  }

  private validateRentalHistory = () => {
    this.getState().user.rentalApplication.rentalHistoryErrors = this.getHistoryErrorCodes(
      this.getState().user.rentalApplication?.application?.residentialHistory || []
    );
  };

  private getHistoryErrorCodes = (history: RentalHistory[]): RentalHistoryErrorCode[][] => {

    return history.map((historyElement: RentalHistory) => {
      const errors: RentalHistoryErrorCode[] = [];
      this.addErrorToArray(errors, this.validateLandlordPhone(historyElement.landlordPhone));
      this.addErrorToArray(errors, this.validateLandlordName(historyElement.landlordName));
      this.addErrorToArray(errors, this.validateRent(historyElement.rent));
      this.addErrorToArray(errors, this.validateMoveInDate(historyElement.moveInDate));
      this.addErrorToArray(errors, this.validateMoveOutDate(historyElement.moveOutDate, historyElement.moveInDate));
      this.addErrorToArray(errors, this.validateReasonForLeaving(historyElement.reasonForLeaving));
      this.addErrorToArray(errors, this.validateAddress1(historyElement.address?.streetAddress1));
      this.addErrorToArray(errors, this.validateAddress2(historyElement.address?.streetAddress2));
      this.addErrorToArray(errors, this.validateCity(historyElement.address?.city));
      this.addErrorToArray(errors, this.validateState(historyElement.address?.state));
      this.addErrorToArray(errors, this.validateZipCode(historyElement.address?.country, historyElement.address?.zipCode));
      this.addErrorToArray(errors, this.validateCountry(historyElement.address?.country));
      return errors;
    });
  };



  private addErrorToArray = (errors: RentalHistoryErrorCode[], error?: RentalHistoryErrorCode, label?: keyof typeof relatedErrors) => {
    if (label) {
      const errorsToRemove = relatedErrors[label] || [];

      for (const relatedError of errorsToRemove) {
        const index = errors.indexOf(relatedError);
        if (index !== -1) {
          errors.splice(index, 1);
        }
      }
    }

    if (error) {
      errors.push(error);
    }
  };

  private validateAddress1 = (address1?: string): RentalHistoryErrorCode | undefined => {
    if (!address1 || address1.length === 0 || address1.length > 50 || !this.addressRegex.test(address1)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress1;
    }
    return undefined;
  };

  private validateAddress2 = (address2?: string): RentalHistoryErrorCode | undefined => {
    if (address2 && (address2.length > 100 || !this.addressRegex.test(address2))) {
      return RentalHistoryErrorCode.InvalidRentalHistoryStreetAddress2;
    }
    return undefined;
  };

  private validateReasonForLeaving = (reasonForLeavin?: string): RentalHistoryErrorCode | undefined => {
    if (reasonForLeavin && reasonForLeavin.length > 500) {
      return RentalHistoryErrorCode.InvalidRentalHistoryReasonForLeaving;
    }
    return undefined;
  };

  private validateCity = (city?: string): RentalHistoryErrorCode | undefined => {
    if (!city || city.length < 2 || city.length > 27 || !this.cityRegex.test(city)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryCity;
    }
    return undefined;
  };

  private validateState = (state?: string): RentalHistoryErrorCode | undefined => {
    if (!state || state.length !== 2) {
      return RentalHistoryErrorCode.InvalidRentalHistoryState;
    }
    return undefined;
  };

  private validateZipCode = (country?: string, zip?: string): RentalHistoryErrorCode | undefined => {
    if (!country || !zip) {
      return RentalHistoryErrorCode.InvalidRentalHistoryZipCode;
    }

    if (country === 'USA' && !this.usZipRegex.test(zip)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryZipCode;
    } else if (country === 'CAN' && !this.canZipRegex.test(zip)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryZipCode;
    }
    return undefined;
  };

  private validateCountry = (country?: string): RentalHistoryErrorCode | undefined => {
    if (!country || country.length < 3 || country.length > 25) {
      return RentalHistoryErrorCode.InvalidRentalHistoryCountry;
    }
    return undefined;
  };

  private validateLandlordName = (name?: string): RentalHistoryErrorCode | undefined => {
    if (name && !this.validateName(name)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryLandlordName;
    }
    return undefined;
  };

  private validateLandlordPhone = (phone?: string): RentalHistoryErrorCode | undefined => {
    if (phone && !this.validatePhone(phone)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryLandlordPhone;
    }
    return undefined;
  };

  private validateName = (name: string | undefined): boolean => {
    if (!name) {
      return false;
    }

    return this.nameRegex.test(name);
  }

  private validatePhone = (phone: string | undefined): boolean => {
    if (!phone) {
      return false;
    }

    const digitsOnly = phone.replace(/\D+/g, '');
    if (/^(00|\+)/.test(phone)) {
      return phone.length > 3;
    } else {
      return digitsOnly.length === 10;
    }
  };

  private validateRent = (rent?: number): RentalHistoryErrorCode | undefined => {
    if (!rent || rent < 0 || rent > MAX_NUMBER) {
      return RentalHistoryErrorCode.InvalidRentalHistoryRent;
    }
    return undefined;
  };

  private validateMoveInDate = (moveInDate?: string): RentalHistoryErrorCode | undefined => {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    moveInDate = moveInDate || '';

    if (moveInDate.length === 0 || !dateRegex.test(moveInDate)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryMoveInDate;
    }
    return undefined;
  };

  private validateMoveOutDate = (moveOutDate?: string, moveInDate?: string): RentalHistoryErrorCode | undefined => {
    if (!moveOutDate) {
      return
    }

    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    moveOutDate = moveOutDate || '';
    moveInDate = moveInDate || ''
    const moveOutDateObj = new Date(moveOutDate);
    const moveInDateObj = new Date(moveInDate);

    if (moveOutDate.length > 0 && !dateRegex.test(moveOutDate)) {
      return RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDate;
    } else if (
      moveOutDate.length > 0
      && dateRegex.test(moveOutDate)
      && dateRegex.test(moveInDate)
      && moveInDateObj >= moveOutDateObj) {
      return RentalHistoryErrorCode.InvalidRentalHistoryMoveOutDateEarlier;

    }
    return undefined;
  };
}
