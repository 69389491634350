import { Component, For } from 'solid-js';
import {
  CITY,
  COUNTRY,
  LANDLORD_NAME,
  LANDLORD_PHONE,
  MOVE_IN_DATE,
  MOVE_OUT_DATE,
  REASON_FOR_LEAVING,
  REMOVE,
  RENT,
  RENTAL_HISTORY,
  STATE,
  STREET_ADDRESS1,
  STREET_ADDRESS2,
  ZIP_CODE,
} from '~/assets/strings';
import LabeledGroup from '~/components/common/Inputs/LabeledGroup';
import LabeledTextInput from '~/components/common/Inputs/LabeledTextInput';
import useUseCase from '~/framework/hooks/useUseCase';
import { useLocalization } from '~/hooks/useLocalization';
import { PresentableRentalHistory, PresentableRentalHistoryError } from '~/presenters/RentalApplicationPresenter';
import { ValidateRentalHistoryUseCase } from '~/use-cases/rental-applications/application-steps/validateRentalHistoryUseCase';

export interface RentalHistoryItemProps {
  index: number;
  history: PresentableRentalHistory;
  errors?: PresentableRentalHistoryError;
  onUpdate: (item: PresentableRentalHistory, index: number) => void;
  onDelete: (index: number) => void;
}

export const RentalHistoryItem: Component<RentalHistoryItemProps> = (props) => {
  const { t } = useLocalization();
  const { execute: validateRentalHistory } = useUseCase(ValidateRentalHistoryUseCase);

  let address1Ref: HTMLInputElement | undefined;
  let address2Ref: HTMLInputElement | undefined;
  let cityRef: HTMLInputElement | undefined;
  let stateRef: HTMLSelectElement | undefined;
  let zipCodeRef: HTMLInputElement | undefined;
  let countryRef: HTMLSelectElement | undefined;
  let landlordNameRef: HTMLInputElement | undefined;
  let landlordPhoneRef: HTMLInputElement | undefined;
  let rentRef: HTMLInputElement | undefined;
  let moveInDateRef: HTMLInputElement | undefined;
  let moveOutDateRef: HTMLInputElement | undefined;
  let reasonForLeavingRef: HTMLInputElement | undefined;

  const onUpdate = (fieldName: string) => {
    const history: PresentableRentalHistory = {
      address: {
        streetAddress1: address1Ref?.value || '',
        streetAddress2: address2Ref?.value || '',
        city: cityRef?.value || '',
        state: stateRef?.value || '',
        zipCode: zipCodeRef?.value || '',
        country: countryRef?.value || '',
      },
      landlordName: landlordNameRef?.value,
      landlordPhone: landlordPhoneRef?.value,
      rent: rentRef?.value,
      moveInDate: moveInDateRef?.value,
      moveOutDate: moveOutDateRef?.value,
      reasonForLeaving: reasonForLeavingRef?.value,
      countries: props.history.countries,
      states: props.history.states,
      selectedCounty: countryRef?.value,
      selectedState: stateRef?.value,
    };
    props.onUpdate(history, props.index);
    validateRentalHistory(fieldName)
  };
  return (
    <div class="grid grid-cols-2 gap-5 py-4">
      <div class="text-md font-semibold text-text-level01">
        {t(RENTAL_HISTORY)} {props.index + 1}
      </div>
      <div class="flex items-end justify-end">
        <button class="rounded-md border border-danger bg-danger-light px-2 text-danger" onClick={() => props.onDelete(props.index)}>
          {t(REMOVE)}
        </button>
      </div>
      <LabeledTextInput
        label={t(LANDLORD_NAME)}
        ref={landlordNameRef}
        value={props.history.landlordName || ''}
        error={props.errors?.rentalHistoryLandlordNameError}
        onChange={() => {
          onUpdate(`history.${props.index}.landlordName`)
        }}
      />
      <LabeledTextInput
        label={t(LANDLORD_PHONE)}
        ref={landlordPhoneRef}
        value={props.history.landlordPhone || ''}
        error={props.errors?.rentalHistoryLandlordPhoneError}
        onChange={() => {
          onUpdate(`history.${props.index}.landlordPhone`)
        }}
        maxLength={10}
      />
      <LabeledTextInput
        label={t(RENT)}
        ref={rentRef}
        value={props.history.rent || ''}
        error={props.errors?.rentalHistoryRentError}
        onChange={() => {
          onUpdate(`history.${props.index}.rent`)
        }}
        required
      />
      <LabeledTextInput
        label={t(MOVE_IN_DATE)}
        type="date"
        ref={moveInDateRef}
        value={props.history.moveInDate || ''}
        error={props.errors?.rentalHistoryMoveInDateError}
        onChange={() => {
          onUpdate(`history.${props.index}.moveInDate`)
          onUpdate(`history.${props.index}.moveOutDate`)
        }}
        required
      />
      <LabeledTextInput
        label={t(MOVE_OUT_DATE)}
        type="date"
        ref={moveOutDateRef}
        value={props.history.moveOutDate || ''}
        error={props.errors?.rentalHistoryMoveOutDateError}
        onChange={() => {
          onUpdate(`history.${props.index}.moveOutDate`)
        }}
      />
      <LabeledTextInput
        label={t(REASON_FOR_LEAVING)}
        ref={reasonForLeavingRef}
        value={props.history.reasonForLeaving || ''}
        error={props.errors?.rentalHistoryReasonForLeavingError}
        onChange={() => {
          onUpdate(`history.${props.index}.reasonForLeaving`)
        }}
        maxLength={500}
      />
      <LabeledTextInput
        label={t(STREET_ADDRESS1)}
        ref={address1Ref}
        value={props.history.address?.streetAddress1}
        error={props.errors?.rentalHistoryStreetAddress1Error}
        onChange={() => {
          onUpdate(`history.${props.index}.address1`)
        }}
        required
        maxLength={50}
      />
      <LabeledTextInput
        label={t(STREET_ADDRESS2)}
        ref={address2Ref}
        value={props.history.address?.streetAddress2}
        error={props.errors?.rentalHistoryStreetAddress2Error}
        onChange={() => {
          onUpdate(`history.${props.index}.address2`)
        }}
        maxLength={100}
      />
      <LabeledTextInput
        label={t(CITY)}
        ref={cityRef}
        value={props.history.address?.city}
        error={props.errors?.rentalHistoryCityError}
        onChange={() => {
          onUpdate(`history.${props.index}.city`)
        }}
        required
        maxLength={27}
      />
      <LabeledGroup
        label={t(STATE)}
        required 
        error={props.errors?.rentalHistoryStateError}
        >
        <select
          class="size-full rounded-md border bg-inputbox-bg px-3 py-[5px]"
          ref={stateRef}
          value={props.history.selectedState}
          
          onChange={() => {
            onUpdate(`history.${props.index}.state`)
          }}
          required>
          <For each={props.history.states}>
            {(state) => (
              <option value={state.value} selected={state.isSelected}>
                {state.label}
              </option>
            )}
          </For>
        </select>
      </LabeledGroup>
      <LabeledTextInput
        label={t(ZIP_CODE)}
        ref={zipCodeRef}
        value={props.history.address?.zipCode}
        error={props.errors?.rentalHistoryZipCodeError}
        onChange={() => {
          onUpdate(`history.${props.index}.zipCode`)
        }}
        required
        maxLength={10}
      />
      <LabeledGroup
        label={t(COUNTRY)}
        required
        error={props.errors?.rentalHistoryCountryError}
      >
        <select
          class="size-full rounded-md border bg-inputbox-bg px-3 py-[5px]"
          ref={countryRef}
          value={props.history.selectedCounty}
          onChange={() => {
            onUpdate(`history.${props.index}.country`)
          }}
          required>
          <For each={props.history.countries}>
            {(country) => (
              <option value={country.value} selected={country.isSelected}>
                {country.label}{' '}
              </option>
            )}
          </For>
        </select>
      </LabeledGroup>
    </div>
  );
};
